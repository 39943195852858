<template>
    <el-container direction="vertical">
        <cost/>
        <!-- header -->
        <app-header></app-header>
        <!-- main -->
        <router-view></router-view>
        <!-- footer -->
        <app-footer></app-footer>
    </el-container>
</template>

<script>
import appHeader from '@/view/app/Header'
import appFooter from '@/view/app/Footer'
import cost from '@/view/cost/index'

export default {
  data () {
    return {}
  },
  components: {
    appHeader,
    appFooter,
    cost
  }
}
</script>
